import React from 'react';

import NavBar from './components/NavBar';
import InfoDialog from './components/InfoDialog';
import ScatterPlot from './components/ScatterPlot';

function App() {
  const [consentGiven, setConsentGiven] = React.useState(false);

  return (
    <div className="App">
      <div style={{ display: 'flex', flexDirection: 'column', width: '100vw', height: '100vh' }}>
        <NavBar />
        {!consentGiven && <InfoDialog setConsentGiven={setConsentGiven} />}
        <ScatterPlot consentGiven={consentGiven} />
      </div>
    </div>
  );
}

export default App;

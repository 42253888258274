import React, { useEffect, useState, useRef, useMemo } from 'react';
import DeckGL from '@deck.gl/react';
import { OrthographicView, OrthographicViewState, PickingInfo } from '@deck.gl/core';
import { ScatterplotLayer, PathLayer } from '@deck.gl/layers';
import { DataFilterExtension, DataFilterExtensionProps } from '@deck.gl/extensions';
import { ArrowsPointingOutIcon, PencilSquareIcon } from '@heroicons/react/24/outline';

const DATA_URL =
  'https://farmsilo.blob.core.windows.net/hentki-pub/emb_clip-vit-b-32-vision.json'; // eslint-disable-line


type ColorPalette = {
  [key: string]: [number, number, number];
};

type DataPoint = {
  x: number;
  y: number;
  url: string;
  id: number;
  source: string;
}

type Coordinate = [number, number];
type BoundingBox = Coordinate[];
type PathLayerData = BoundingBox[];

export default function ScatterPlot({
  data = DATA_URL,
  consentGiven = false,
}: {
  data?: string | DataPoint[];
  consentGiven: boolean;
}) {
  const [isHovering, setIsHovering] = useState(false);
  const [isSelecting, setIsSelecting] = useState(false);
  const [isControllerActive, setIsControllerActive] = useState(true);
  const [startCoordinates, setStartCoordinates] = useState<[number, number] | null>(null);
  const [startCoordinatesPixel, setStartCoordinatesPixel] = useState<[number, number] | null>(null);
  const [selectionBox, setSelectionBox] = useState<PathLayerData>([]);
  const [selectionBoxPixel, setSelectionBoxPixel] = useState<BoundingBox>([]);

  const initialViewState: OrthographicViewState = {
    target: [1.6, 0.5, 0],
    zoom: 7
  };
  const [viewState, setViewState] = useState(initialViewState)

  const [selection, setSelection] = useState<DataPoint[]>([]);
  const [isMasterChecked, setIsMasterChecked] = useState<boolean>(true);


  const [pathLayer, setPathLayer] = useState<PathLayer | null>(null);
  const [scatterplotLayer, setScatterplotLayer] = useState<ScatterplotLayer<DataPoint> | null>(null);

  const deckRef = useRef<any>();
  const imageContainerRef = useRef<HTMLDivElement>(null);

  const colors: ColorPalette = useMemo(() => ({
    ecchi: [255, 89, 94],
    hentai: [255, 146, 76],
    Nekomimi: [255, 202, 58],
    thighdeology: [138, 201, 38],
    futanari: [82, 166, 117],
    rule34: [25, 130, 196],
  }), []);

  const [filter, setFilter] = useState<string[]>(Object.keys(colors));

  const handleCheckboxChange = (className: string) => {
    setFilter(prevState =>
      prevState.includes(className)
        ? prevState.filter(item => item !== className)
        : [...prevState, className]
    );
  };

  const handleMasterCheckboxChange = () => {
    setIsMasterChecked(prevState => {
      const newState = !prevState;
      setFilter(newState ? Object.keys(colors) : []);
      return newState;
    });
  };

  const handleResetView = () => {
    setViewState(initialViewState);
  };

  const handleSelect = () => {
    setIsSelecting(!isSelecting);
  };


  const handleClick = (info: any) => {
    if (info.object) {
      setSelection([info.object]);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === ' ' && !isSelecting) {
      setIsSelecting(true);
      console.log('Space down');
    }
  }

  const handleKeyUp = (event: React.KeyboardEvent) => {
    if (event.key === ' ' && isSelecting) {
      setIsSelecting(false);
      console.log('Space up');
    }
  }

  const handleOnDrag = (info: any) => {
    if (isSelecting) {
      const [x, y] = info.coordinate;
      const pixelx = info.x;
      const pixely = info.y;
      if (!startCoordinates || !startCoordinatesPixel) {
        setStartCoordinates([x, y]);
        setStartCoordinatesPixel([pixelx, pixely]);
      } else {
        const [x0, y0] = startCoordinates;
        const [pixelx0, pixely0] = startCoordinatesPixel;
        let newBB: BoundingBox = [[x0, y0], [x, y0], [x, y], [x0, y], [x0, y0]];
        let newBBPixel: BoundingBox = [[pixelx0, pixely0], [pixelx, pixely0], [pixelx, pixely], [pixelx0, pixely], [pixelx0, pixely0]];
        setSelectionBox([newBB]);
        setSelectionBoxPixel(newBBPixel);
      }
    }
  };

  const handleOnDragEnd = (info: any, event: any) => {
    if (isSelecting) {

      if (selectionBoxPixel.length > 0) {
        setSelection([]);
        const box = selectionBoxPixel;
        const minX = Math.min(...box.map(point => point[0]));
        const maxX = Math.max(...box.map(point => point[0]));
        const minY = Math.min(...box.map(point => point[1]));
        const maxY = Math.max(...box.map(point => point[1]));
        const width = maxX - minX;
        const height = maxY - minY;

        console.log('Selection box:', { minX, maxX, minY, maxY, width, height });

        const pickInfos: PickingInfo[] = deckRef.current.deck.pickObjects({
          x: minX,
          y: minY,
          width: width,
          height: height,
          maxObjects: 30
        });
        console.log('Pick infos:', pickInfos);
        setSelection(pickInfos.map(info => info.object));
      }
      setIsControllerActive(true);
      setStartCoordinates(null);
      setStartCoordinatesPixel(null);
    }
  }

  useEffect(() => {
    setPathLayer(new PathLayer({
      id: 'selection-box',
      data: selectionBox,
      getColor: [108, 88, 121],
      getWidth: 2,
      widthUnits: 'pixels',
      getPath: d => d,
    }));
  }, [selectionBox]);

  useEffect(() => {
    setScatterplotLayer(new ScatterplotLayer<DataPoint, DataFilterExtensionProps<DataPoint>>({
      id: 'scatter-plot',
      data,
      radiusScale: 1,
      radiusUnits: 'common',
      getPosition: d => [d.x, d.y, 0],
      getRadius: 0.03,
      radiusMaxPixels: 5,
      autoHighlight: true,
      highlightColor: [108, 88, 121],
      getFillColor: d => colors[d.source],
      pickable: true,
      onClick: handleClick,
      onHover: ({ object }) => (setIsHovering(Boolean(object))),
      getFilterCategory: d => d.source,
      filterCategories: filter,
      extensions: [new DataFilterExtension({ categorySize: 1 })]
    }));
  }, [data, filter, colors]);

  //useEffect(() => {
  //  setBitmapLayer(new BitmapLayer({
  //    id: 'bitmap-layer',
  //    bounds: [-5.907800233823115, -4.4258238802608005, 8.863074338895137, 5.535973025387143],
  //    image: 'https://farmsilo.blob.core.windows.net/hentki-pub/kde.svg',
  //  }));
  //}, []);

  useEffect(() => {
    if (imageContainerRef.current) {
      imageContainerRef.current.scrollTop = 0;
    }
  }, [selection]);

  const view = new OrthographicView({
    flipY: false,
    near: 0.0001,
    far: 2,
  });

  if (!consentGiven) {
    return null;
  }

  return (
    <div style={{ display: 'flex', flex: '1' }}>
      <div onKeyDown={handleKeyDown} onKeyUp={handleKeyUp} style={{ flex: '0 0 70%', position: 'relative' }}>
        <DeckGL
          ref={deckRef}
          layers={[scatterplotLayer, pathLayer]}
          views={view}
          viewState={viewState}
          onViewStateChange={({ viewState }) => setViewState(viewState)}
          initialViewState={initialViewState}
          controller={isControllerActive}
          style={{ position: 'absolute', width: '100%', height: '100%' }}
          getCursor={({ isDragging }) => {
            if (isSelecting) {
              return 'crosshair';
            }
            return isDragging ? 'grabbing' : (isHovering ? 'pointer' : 'grab');
          }}
          onDrag={handleOnDrag}
          onDragStart={() => { if (isSelecting) setIsControllerActive(false) }}
          onDragEnd={handleOnDragEnd}
        />
        <div style={{ position: 'absolute', bottom: '10px', right: '10px', backgroundColor: 'rgba(255, 255, 255, 0.8)', padding: '10px', borderRadius: '5px', fontSize: '12px' }}>
          {Object.keys(colors).map(cat => (
            <div key={cat} className="flex items-center mb-1.5">
              <input
                type="checkbox"
                checked={filter.includes(cat)}
                onChange={() => handleCheckboxChange(cat)}
                style={{ marginRight: '10px' }}
              />
              <div className="w-3 h-3 rounded-full mr-1.5" style={{ backgroundColor: `rgb(${colors[cat].join(',')})` }}></div>
              <span>{cat}</span>
            </div>
          ))}
          <div className="flex items-center mb-1.5">
            <input
              type="checkbox"
              checked={isMasterChecked}
              onChange={handleMasterCheckboxChange}
              className="mr-3"
            />
          </div>
        </div>
        <div className="absolute bottom-2.5 left-2.5 flex flex-col gap-2.5">
          <button onClick={handleSelect} className={`w-10 h-10 border-2 rounded-md flex items-center justify-center ${isSelecting ? 'bg-purple-500' : 'bg-white'} ${isSelecting ? 'border-purple-500' : 'border-purple-500'}`}>
            <PencilSquareIcon className={`w-6 h-6 ${isSelecting ? 'text-white' : 'text-purple-500'}`} />
          </button>
          <button onClick={handleResetView} className="w-10 h-10 border-2 border-purple-500 rounded-md flex items-center justify-center bg-white">
            <ArrowsPointingOutIcon className="text-purple-500 w-6 h-6" />
          </button>
        </div>
      </div>
      <div ref={imageContainerRef} className="flex-[0_0_30%] flex flex-col overflow-y-scroll scrollbar-none bg-white border-l border-purple-500 max-h-[96vh]">
        {selection.map((object, index) => (
          <img loading='lazy' key={index} src={object.url} alt={`Selected object ${index}`} className="max-w-full" />
        ))}
      </div>
    </div>
  )
}